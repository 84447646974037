<template>
  <div class="tenantManager">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :isManySelect="true"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          clearable
          placeholder="请输入应用名称"
          label="应用名称"
          v-model="searchParam.title"
          :width="250"
        ></v-input>
        <v-select
          clearable
          placeholder="请选择"
          :options="pagesType"
          v-model="searchParam.targetType"
          @change="$refs.list.search()"
          label="应用类型"
        />
        <v-select
          clearable
          placeholder="请选择"
          :options="inType"
          v-model="searchParam.sourceType"
          @change="$refs.list.search()"
          label="入口类型"
        />
        <v-select
          clearable
          :options="tenantList"
          v-model="searchParam.tenantId"
          @change="$refs.list.search()"
          label="租户"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="toEdit(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getTenantAppListUrl,
  deleteTenantAppListUrl,
  tenantListUrl,
} from "./api.js";

import {
  pagesType,
  pagesTypeMap,
  inType,
  isShowHomeTypeMap,
  isShowHomeType,
  inTypeMap,
} from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "topicList",
  data() {
    let _this = this;
    return {
      pagesType,
      inType,
      searchParam: {
        title: "",
        targetType: "",
        sourceType: "",
        tenantId: "",
      },
      teacherSignList: [],
      tenantList: [],
      tableUrl: getTenantAppListUrl,
      headers: [
        {
          prop: "modelTitle",
          label: "应用名称",
        },
        {
          prop: "modelIconPic",
          label: "应用图标",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "targetType",
          label: "应用类型",
          formatter: (row, prop) => pagesTypeMap[row.targetType],
        },
        {
          prop: "sourceType",
          label: "入口类型",
          formatter: (row, prop) => inTypeMap[row.sourceType],
        },
        {
          prop: "updateTime",
          label: "创建时间",
        },
        {
          prop: "isHomeShow",
          label: "是否首页展示",
          formatter: (row, prop) => isShowHomeTypeMap[row.isHomeShow],
        },
        {
          prop: "sortIndex",
          label: "排序",
        },
        {
          prop: "tenantName",
          label: "租户",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.getTenantList();
  },
  methods: {
    getTenantList() {
      let params = {};
      this.tenantList = [];
      this.$axios.get(`${tenantListUrl}`).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            item.label = item.tenantName;
            item.value = item.tenantId;
            this.tenantList.push(item);
          });
        } else {
        }
      });
    },
    goback() {
      this.$router.go(-1);
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${deleteTenantAppListUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      let _this = this;
      this.$router.push({
        name: "tenantManagerForm",
      });
    },
    toEdit(row) {
      let _this = this;
      this.$router.push({
        name: "tenantManagerForm",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tenantManager {
  box-sizing: border-box;
  height: 100%;
}
</style>
